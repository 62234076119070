











































































































































































import { Component, Vue } from "vue-property-decorator";
import { Form as elForm } from "element-ui";
import { Md5 } from "ts-md5";
import { clearFilter } from "@/utils/base";
import { PostLogin, PostChangePassword, AutotLogin } from "@/request/account";
import { GetCenter } from "@/request/common";
import Remember from "./remember.vue";
@Component({
  components: {
    Remember,
  },
})
export default class Login extends Vue {
  private defaultBg: any = "url('/common/login-bg.png')";
  private type: any = "text";
  private ifRember: any = true;
  private ifShowChangePassword: any = false;
  private window: any = window;
  private oldPassword: any = "";
  private newPassword: any = "";
  private renewPassword: any = "";
  private centerList: any = [];
  private data: any = {};
  private paws: any = [];
  private showPassword: any = false;
  private info: any = {
    用户名: "",
    密码: "",
  };
  private loading: any = false;
  private visible: any = false;
  private rules: any = {
    用户名: [{ required: true, message: "用户名必须填写", trigger: "blur" }],
    密码: [{ required: true, message: "密码必须填写", trigger: "blur" }],
  };
  private get configuration() {
    return this.$store.state.configuration;
  }
  private changeEye(val: any) {
    this.showPassword = val;
    if (val) {
      this.type = "text";
    } else {
      this.type = "password";
    }
  }
  private pawInput(e: any) {
    if (this.info["密码"] && !this.showPassword) {
      this.type = "password";
    } else {
      this.type = "text";
    }
  }
  private focus() {
    if (localStorage.getItem("kypaws")) {
      this.paws = JSON.parse(localStorage.getItem("kypaws") || "");
    }
  }
  private update(e: any) {
    this.type = "password";
    this.info["用户名"] = e.name;
    this.info["密码"] = e.paw;
    this.visible = false;
  }
  private delPaw(e: any) {
    if (e.length == 0) {
      this.type = "text";
    }
    this.paws = JSON.parse(JSON.stringify(e));
  }
  /**
   * @description 登录
   */
  private login(): void {
    if (!this.info["用户名"]) {
      this.$message.warning("请输入用户名");
      return;
    }
    if (!this.info["密码"]) {
      this.$message.warning("请输入密码");
      return;
    }
    const Base64 = require("js-base64").Base64;
    const params: any = {
      account: this.info["用户名"],
      密码: Md5.hashStr("qihuang" + this.info["密码"]),
      // 密码: Base64.encode(this.info["密码"]),
    };
    PostLogin(this, params)
      .then((res) => {
        if (this.ifRember) {
          // 如果没有存过则储存，如果已经储存过则更新
          if (this.paws.length === 0) {
            this.paws.push({
              name: this.info["用户名"],
              paw: this.info["密码"],
            });
          } else {
            let ifRepeat: any = false;
            let i: any = "";
            this.paws.forEach((ele: any, index: any) => {
              if (ele.name == this.info["用户名"]) {
                i = index;
                ifRepeat = true;
              }
            });
            if (ifRepeat) {
              this.paws.splice(i, 1);
            }
            this.paws.push({
              name: this.info["用户名"],
              paw: this.info["密码"],
            });
          }
        }
        localStorage.setItem("kypaws", JSON.stringify(this.paws));
        this.data = res;
        if (res.changed) {
          this.ifShowChangePassword = true;
        } else {
          this.go();
        }
        // 如果没有改密码需要提示
        // if (res.change_password) {
        //   this.$alert("为保证账户安全，请修改您的账户密码", "", {
        //     confirmButtonText: "我知道了",
        //     callback: (action) => {
        //       this.goRoute();
        //     },
        //   });
        // } else {
        //   this.goRoute();
        // }
      })
      .catch((error: any) => {
        // if (typeof error === "string") {
        //   this.$message.error(error);
        // }
      });
  }
  /**
   * @description 确定修改密码
   */
  private confirmChangePassword(): void {
    const reg =
      /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!@#$%^&*\_\-?.])[a-zA-Z\d!#@*&.]{8,}$/;
    if (!this.newPassword) {
      this.$message.warning("旧密码不能为空");
      return;
    }
    if (!this.newPassword) {
      this.$message.warning("新密码不能为空");
      return;
    }
    if (!this.renewPassword) {
      this.$message.warning("重复新密码不能为空");
      return;
    }
    if (!reg.test(this.oldPassword)) {
      this.$message.warning(
        "旧密码长度必须8位以上，且包含英文大小写和数字以及特殊字符"
      );
      return;
    }
    if (!reg.test(this.newPassword)) {
      this.$message.warning(
        "新密码长度必须8位以上，且包含英文大小写和数字以及特殊字符"
      );
      return;
    }
    if (this.newPassword !== this.renewPassword) {
      this.$message.warning("两次输入的密码不一致，请重新输入");
      // this.newPassword = "";
      // this.renewPassword = "";
      return;
    }
    const Base64 = require("js-base64").Base64;
    const params = {
      // 旧密码: Base64.encode(this.oldPassword),
      // 新密码: Base64.encode(this.newPassword),
      旧密码: Md5.hashStr("qihuang" + this.oldPassword),
      新密码: Md5.hashStr("qihuang" + this.newPassword),
    };
    this.$store.commit("updateToken", this.data.token);
    PostChangePassword(this, params).then(() => {
      this.$message.success("修改成功");
      this.newPassword = "";
      this.renewPassword = "";
      this.ifShowChangePassword = false;
      this.go();
    });
  }
  private go() {
    const token = this.data.token;
    const user = this.data.user;
    this.$store.commit("updateToken", token);
    this.$store.commit("updateUser", user);
    this.$store.commit("updateCenter", "");
    clearFilter();
    this.goRoute();
  }
  private goRoute() {
    const configuration = this.$store.state.configuration;
    GetCenter(this).then((res: any) => {
      this.centerList = res;
      this.$store.commit("updateCenters", res);
      if (configuration["首页"].isShow) {
        this.$router.push("/main/home/home-show");
      } else if (this.ifShowPart("center_show")) {
        this.$router.push("/main/datacenter/pool/pool-list");
      } else if (this.ifShowPart("show")) {
        this.$router.push("/main/storehouse/storehouse-select");
      } else if (this.ifShowPart("label_show")) {
        this.$router.push("/main/research/tongue/list");
      } else if (this.ifShowPart("analysis_show")) {
        let paths: any = [];
        this.configuration["分析挖掘"].child.forEach((ele: any) => {
          if (ele.isShow) {
            paths.push(ele.path);
          }
        });
        this.$router.push(paths[0]);
      } else if (this.ifShowPart("topic_show")) {
        this.$router.push("/main/topic/caijishuoming/folder");
      } else if (configuration["用户权限管理"].isShow) {
        this.$router.push("/main/manage/zhanghu/list");
      }
    });
  }
  private ifShowPart(key: any) {
    let result: any = false;
    this.centerList.forEach((ele: any) => {
      if (ele[key]) {
        result = true;
      }
    });
    return result;
  }
  private mounted() {
    if (localStorage.getItem("kypaws")) {
      this.paws = JSON.parse(localStorage.getItem("kypaws") || "");
    }
    if (this.$route.query.session_code && this.$route.query.user_id) {
      // 需要自动登录
      // http://localhost:8080/#/access/login?session_code=session_code&&user_id=user_id
      // https://saas-test.i-tcm.cn/#/access/login?session_code=session_code&&user_id=user_id
      this.loading = true;
      const params: any = {
        session_code: this.$route.query.session_code,
        user_id: this.$route.query.user_id,
      };
      AutotLogin(this, params)
        .then((res: any) => {
          this.data = res;
          this.go();
        })
        .catch(() => {
          this.loading = false;
        });
    } else {
      const token = localStorage.getItem("token");
      if (token) {
        localStorage.setItem("center", "{}");
        const configuration = this.$store.state.configuration;
        if (configuration["首页"].isShow) {
          this.$router.push("/main/home/home-show");
        } else if (configuration["数据中心"].isShow) {
          this.$router.push("/main/datacenter/pool/pool-list");
        } else if (configuration["专病数据库"].isShow) {
          this.$router.push("/main/storehouse/storehouse-select");
        } else if (configuration["数据研究"].isShow) {
          this.$router.push("/main/research/tongue/list");
        } else if (configuration["分析挖掘"].isShow) {
          let paths: any = [];
          this.configuration["分析挖掘"].child.forEach((ele: any) => {
            if (ele.isShow) {
              paths.push(ele.path);
            }
          });
          this.$router.push(paths[0]);
        } else if (configuration["用户权限管理"].isShow) {
          this.$router.push("/main/manage/zhanghu/list");
        }
      }
    }
  }
}
