import { render, staticRenderFns } from "./ky.vue?vue&type=template&id=0d74133d&scoped=true&"
import script from "./ky.vue?vue&type=script&lang=ts&"
export * from "./ky.vue?vue&type=script&lang=ts&"
import style0 from "./ky.vue?vue&type=style&index=0&id=0d74133d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d74133d",
  null
  
)

export default component.exports